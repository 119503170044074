import {
    AddChildResponse,
    ChartDataUpdate,
    ChartStatusResponse,
    Child,
    ChildrenResponse,
    ChildResponse,
    RhConfig,
    GenericResponse,
    LoginCredsRequest,
    LoginCredsResponse,
    RegisterRequest,
    RegisterResponse,
    RemoveChildResponse,
    PasswordResetRequest
} from "./Types";
import axios, { AxiosResponse } from "axios";
import { lsGetAuthUser } from "./LocalStorageService";
import { isNotBlank } from "./CommonServices";
import { showLoading } from "../store/LoadingReducer";


const BASE_BACKEND_URL = "https://api.youngramadanheroes.com/api/";


const instance = axios.create({
    baseURL: BASE_BACKEND_URL,
    timeout: 15000,
});


instance.interceptors.request.use(function (config) {
    showLoading(true);
    return config
}, function (error) {
    showLoading(false);
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    showLoading(false);
    return response;
}, function (error) {
    showLoading(false);
    return Promise.reject(error);
});


const responseBody = (response: AxiosResponse) => response.data;


const requests = {
    get: (url: string, headers?: {}) => instance.get(url, { headers }).then(responseBody),
    post: (url: string, body: {}, headers?: {}) => instance.post(url, body, { headers }).then(responseBody),
    put: (url: string, body: {}, headers?: {}) => instance.put(url, body, { headers }).then(responseBody),
    delete: (url: string, headers?: {}) => instance.delete(url, { headers }).then(responseBody),
};


const createAuthToken = (): { Authorization: string } | undefined => {
    // Get it from redux instead of ls
    const authUser = lsGetAuthUser();
    if (authUser && isNotBlank(authUser.sessionToken)) {
        return { Authorization: `Bearer ${authUser?.sessionToken}` };
    }
}


export const apiLogin = (loginCredsRequest: LoginCredsRequest): Promise<LoginCredsResponse> => {
    const formData = new FormData();

    formData.append('email', loginCredsRequest.email);
    formData.append('password', loginCredsRequest.passwordHash);

    return requests.post("loginnorecaptcha", formData);
}


export const apiRegister = (registerRequest: RegisterRequest): Promise<RegisterResponse> => {
    const formData = new FormData();

    formData.append('email', registerRequest.email);
    formData.append('first_name', registerRequest.first_name);
    formData.append('last_name', registerRequest.last_name);
    formData.append('phone_number', registerRequest.phone_number);
    formData.append('password', registerRequest.passwordHash);
    formData.append('masjid_id', registerRequest.masjid_id);

    return requests.post("registernorecaptcha", formData);
}


export const apiGetChildren = (): Promise<ChildrenResponse> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "session token not found in local storage",
            Success: false,
            Children: []
        }));
    }
    return requests.get('children', authTokenHeader);
}


export const apiIbadhaChart = (childId: string): Promise<ChartStatusResponse> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "session token not found in local storage",
            Success: false,
            Dates: []
        }));
    }
    return requests.get(`chartstatus?childid=${childId}`, authTokenHeader);
}


export const apiAddChild = (child: Child): Promise<AddChildResponse> => {

    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "session token not found in local storage",
            Success: false,
            childid: ""
        }));
    }


    return requests.post(`addchildsdetails`, child, authTokenHeader);
}

export const apiPostIbadhaChart = (childId: string,parentEmail: string): Promise<ChartStatusResponse> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "session token not found in local storage",
            Success: false,
            Dates: []
        }));
    }
    const formData = new FormData();
    formData.append('email', parentEmail);
    formData.append('childid', childId);
    return requests.post(`admin/chartforchildid`, formData, authTokenHeader);
}

export const apiUpdateChart = (chartDataUpdate: ChartDataUpdate): Promise<GenericResponse> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "session token not found in local storage",
            Success: false,
        }));
    }

    return requests.post(`updatechart`, chartDataUpdate, authTokenHeader);
}


export const apiRemoveChild = (childId: string): Promise<RemoveChildResponse> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "Session token not found in local storage",
            Success: false,
            childid: ""
        }));
    }

    return requests.get(`removechild?childid=${childId}`, authTokenHeader);
}


export const apiGetChildDetails = (childId: string): Promise<ChildResponse> => {

    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "Session token not found in local storage",
            Success: false
        }));
    }

    return requests.get(`getchildsdetails?childid=${childId}`, authTokenHeader);
}


export const apiRhConfig = (): Promise<RhConfig> => {
    return requests.get("config");
}


export const apiPasswordReset = (passwordResetRequest: PasswordResetRequest): Promise<GenericResponse> => {
    const formData = new FormData();
    formData.append('email', passwordResetRequest.email);
    return requests.post("passwordreset", formData);
}

export const apiGetAccountsReport = (): Promise<any> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "Session token not found in local storage",
            Success: false
        }));
    }
    return requests.get(`admin/report1`, authTokenHeader);
}
export const apiGetReport2 = (): Promise<any> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "Session token not found in local storage",
            Success: false
        }));
    }
    return requests.get(`admin/report2`, authTokenHeader);
}
export const apiGetParents = (): Promise<any> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "Session token not found in local storage",
            Success: false
        }));
    }
    return requests.get(`admin/parents`, authTokenHeader);
}
export const apiGetIbadahSummary = (): Promise<any> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "Session token not found in local storage",
            Success: false
        }));
    }
    return requests.get(`admin/ibadahsummary`, authTokenHeader);
}
export const apiChildrenData = (): Promise<any> => {
    const authTokenHeader = createAuthToken();
    if (!authTokenHeader) {
        return new Promise((resolve, reject) => resolve({
            Message: "Session token not found in local storage",
            Success: false
        }));
    }
    return requests.get(`admin/parents/csv`, authTokenHeader);
}

/*
curl --location --request POST 'https://api.youngramadanheroes.com/api/passwordreset' \
--form 'email="stariqch+rh04@gmail.com"'

*/